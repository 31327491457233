import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
}
export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"   transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">


<path d="M3102 9713 c-11 -4 -112 -202 -112 -218 0 -2 -24 -54 -54 -117 -86
-181 -207 -440 -222 -474 -7 -18 -47 -102 -89 -189 -41 -86 -75 -158 -75 -160
0 -2 -29 -65 -65 -140 -36 -75 -65 -138 -65 -139 0 -2 -34 -74 -75 -160 -41
-86 -75 -159 -75 -161 0 -2 -24 -55 -54 -117 -93 -197 -127 -270 -191 -408
-35 -74 -90 -193 -124 -263 -33 -70 -61 -129 -61 -131 0 -4 -80 -174 -172
-366 -33 -69 -67 -143 -76 -165 -9 -22 -40 -89 -69 -148 -29 -59 -53 -109 -53
-112 0 -3 -57 -126 -134 -289 -19 -39 -100 -213 -181 -386 -81 -173 -169 -361
-196 -418 -27 -57 -49 -105 -49 -108 0 -2 -26 -59 -59 -127 -61 -128 -98 -206
-150 -321 -18 -37 -56 -118 -85 -180 -29 -61 -79 -167 -111 -236 -32 -69 -80
-172 -106 -229 -27 -57 -49 -106 -49 -108 0 -3 16 19 37 48 164 242 332 471
497 679 52 66 246 301 256 310 3 3 32 36 65 75 78 90 307 336 391 419 186 183
355 341 493 460 156 134 489 389 603 461 48 30 47 29 134 220 15 33 41 92 59
130 18 39 47 101 64 139 18 38 47 101 65 140 18 39 46 99 61 134 16 34 32 62
36 62 7 0 41 -69 94 -190 56 -128 115 -250 121 -250 3 0 34 15 67 34 176 95
494 230 771 327 93 32 171 61 174 63 2 3 -8 31 -23 63 -56 120 -160 342 -215
458 -31 66 -65 138 -75 160 -23 51 -87 188 -159 337 -31 65 -56 120 -56 122 0
2 -24 55 -54 118 -29 62 -69 147 -88 188 -20 41 -104 221 -188 400 -84 179
-164 350 -177 380 -14 30 -52 111 -85 180 -33 69 -69 144 -79 168 -10 23 -20
42 -21 41 -2 0 -9 -3 -16 -6z"/>
<path d="M7885 9683 c-174 -22 -390 -71 -510 -113 -102 -37 -338 -156 -426
-216 -353 -239 -577 -567 -660 -964 -56 -265 -37 -595 46 -814 29 -76 89 -202
108 -227 9 -11 54 -20 172 -33 333 -36 550 -72 880 -147 357 -82 763 -215
1200 -394 50 -20 95 -40 100 -45 6 -4 21 -11 35 -15 122 -37 691 -334 968
-505 34 -21 62 -35 62 -31 0 4 -23 45 -52 91 -204 336 -576 629 -1178 927
-150 74 -406 193 -415 193 -2 0 -47 20 -99 45 -53 25 -99 45 -102 45 -3 0 -72
33 -155 74 -315 157 -436 272 -454 432 -26 232 145 450 425 542 73 25 247 52
330 52 75 0 231 -26 310 -52 195 -63 371 -200 460 -356 37 -65 44 -70 69 -49
9 8 48 36 86 62 39 27 88 61 110 77 22 16 69 49 105 74 36 24 108 75 161 112
52 37 152 107 222 155 97 68 125 92 120 105 -33 97 -147 296 -221 387 -85 105
-242 247 -338 305 -21 13 -41 26 -44 29 -15 17 -183 97 -276 132 -252 93 -479
130 -794 127 -118 0 -228 -3 -245 -5z"/>
<path d="M5835 7034 c-203 -8 -710 -67 -815 -94 -20 -5 -74 -17 -120 -25 -310
-58 -809 -217 -1115 -355 -234 -105 -561 -276 -703 -366 -29 -19 -54 -34 -56
-34 -3 0 -87 -55 -188 -123 -706 -473 -1319 -1054 -1921 -1822 -56 -71 -106
-134 -110 -140 -32 -39 -207 -280 -272 -375 -109 -160 -119 -174 -125 -180 -3
-3 -13 -17 -23 -32 l-17 -28 629 0 629 0 43 68 c24 37 46 69 49 72 3 3 28 37
55 75 326 464 823 971 1315 1341 220 165 591 404 681 439 18 7 19 5 124 -225
29 -63 62 -135 73 -160 70 -154 149 -324 174 -380 17 -36 61 -132 98 -215 37
-82 105 -231 150 -330 45 -99 91 -200 102 -225 11 -25 34 -74 50 -110 16 -36
36 -78 43 -95 8 -16 37 -79 64 -140 l50 -110 631 -3 c454 -1 630 1 630 9 0 6
-7 23 -15 37 -8 15 -33 68 -56 117 -81 177 -169 365 -319 685 -83 179 -163
350 -177 380 -14 30 -48 102 -76 160 -27 58 -73 157 -102 220 -29 63 -86 187
-128 275 -190 405 -247 529 -247 536 0 5 19 13 42 19 80 22 304 72 358 81 30
4 111 17 180 28 336 52 824 61 1183 22 296 -32 689 -105 877 -162 19 -5 69
-19 110 -30 305 -81 778 -267 1140 -447 l175 -87 18 -60 c58 -190 -45 -389
-259 -501 -101 -53 -172 -78 -309 -105 -150 -31 -450 -33 -600 -5 -334 62
-589 223 -773 488 -26 37 -51 68 -55 68 -5 0 -81 -51 -170 -114 -89 -63 -182
-129 -207 -146 -25 -17 -91 -64 -147 -103 -56 -40 -148 -103 -203 -142 -56
-38 -107 -75 -113 -81 -7 -6 -24 -18 -39 -28 -16 -10 -28 -23 -28 -29 0 -24
149 -247 224 -337 68 -81 257 -260 329 -313 186 -138 375 -235 617 -317 61
-21 274 -70 365 -84 741 -116 1488 46 1958 423 136 110 274 267 356 406 85
145 109 201 156 367 103 362 81 758 -61 1094 -25 60 -28 62 -137 129 -378 231
-850 464 -1295 639 -393 154 -1020 331 -1372 386 -30 5 -113 18 -185 30 -300
47 -818 77 -1140 64z"/>
<path d="M4269 3206 c-120 -27 -213 -106 -251 -214 -24 -66 -24 -229 -1 -298
19 -58 63 -123 104 -153 81 -60 212 -88 339 -72 141 18 233 79 287 190 25 51
27 67 28 171 0 156 -20 218 -94 289 -93 87 -254 121 -412 87z m210 -181 c52
-26 66 -66 66 -186 0 -96 -2 -109 -25 -142 -31 -45 -64 -60 -137 -59 -60 0
-90 16 -127 65 -15 20 -21 47 -24 109 -9 173 43 244 170 233 26 -2 61 -11 77
-20z"/>
<path d="M8200 3213 c-51 -8 -116 -37 -157 -70 -33 -27 -46 -46 -59 -88 -28
-99 0 -180 81 -237 36 -25 72 -36 277 -89 37 -10 68 -32 68 -50 0 -5 -9 -18
-20 -29 -16 -16 -33 -20 -79 -20 -81 0 -107 11 -121 54 l-12 36 -110 0 -110 0
7 -47 c18 -120 88 -178 249 -203 192 -32 349 20 408 133 31 61 31 159 -1 210
-30 49 -106 92 -201 113 -161 36 -210 55 -210 83 0 43 116 69 165 37 14 -9 25
-25 25 -36 0 -26 26 -32 135 -28 l90 3 -3 40 c-9 115 -145 196 -322 194 -41
-1 -86 -4 -100 -6z"/>
<path d="M844 3203 c-6 -5 -32 -66 -57 -138 -83 -236 -127 -360 -136 -380 -13
-28 -71 -190 -71 -198 0 -4 51 -7 113 -7 l112 0 15 48 16 47 137 3 c106 2 137
0 137 -10 0 -7 5 -30 11 -50 l10 -38 121 0 c110 0 120 1 115 18 -3 9 -60 165
-126 346 -67 182 -121 335 -121 342 0 6 -9 14 -19 17 -31 8 -245 8 -257 0z
m136 -218 c0 -8 4 -23 10 -33 9 -15 60 -192 60 -206 0 -4 -37 -6 -81 -6 -95 0
-90 -18 -39 153 29 96 50 134 50 92z"/>
<path d="M1476 3203 c-3 -4 -6 -110 -6 -237 0 -169 4 -246 15 -285 33 -124
119 -189 281 -212 166 -23 330 34 389 136 39 68 46 133 43 375 l-3 225 -110 0
-110 0 -5 -245 c-5 -263 -9 -283 -61 -310 -31 -17 -126 -14 -154 4 -47 31 -50
47 -55 306 l-5 245 -107 2 c-58 1 -109 -1 -112 -4z"/>
<path d="M2314 3197 c-3 -8 -4 -49 -2 -93 l3 -79 115 -1 115 -1 3 -271 2 -272
115 0 115 0 2 271 3 270 115 2 115 2 0 90 0 90 -348 3 c-288 2 -348 1 -353
-11z"/>
<path d="M3136 3201 c-2 -3 -4 -167 -5 -363 l-2 -358 118 0 118 0 0 137 0 138
133 3 132 3 0 -141 0 -140 115 0 115 0 -2 363 -3 362 -110 0 -110 0 -5 -133
c-3 -72 -8 -132 -12 -132 -5 0 -65 0 -134 0 l-126 0 3 133 3 132 -112 1 c-62
1 -114 -2 -116 -5z"/>
<path d="M4928 3204 c-5 -4 -8 -169 -8 -366 l0 -358 115 0 114 0 3 133 3 132
37 1 c20 0 42 -2 47 -5 11 -7 121 -233 121 -250 0 -7 41 -11 130 -11 72 0 130
2 130 5 0 7 -106 208 -132 250 -23 39 -23 52 3 60 53 17 107 93 116 163 11 96
-39 185 -126 225 -42 19 -68 21 -296 25 -137 2 -253 0 -257 -4z m418 -172 c14
-9 34 -47 34 -65 0 -8 -11 -26 -25 -41 -23 -24 -30 -26 -115 -26 l-90 0 0 70
0 70 93 0 c50 0 97 -4 103 -8z"/>
<path d="M5760 2845 l0 -365 120 0 120 0 0 365 0 365 -120 0 -120 0 0 -365z"/>
<path d="M6114 3196 c-3 -7 -4 -48 -2 -92 l3 -79 118 0 117 0 0 -272 0 -273
115 0 115 0 2 272 3 272 117 -3 c137 -3 132 -7 124 114 l-4 70 -351 2 c-282 2
-353 0 -357 -11z"/>
<path d="M6880 3199 c0 -6 17 -36 39 -67 21 -32 57 -86 79 -122 23 -36 45 -69
49 -75 5 -5 32 -47 61 -93 l52 -83 0 -139 0 -140 115 0 115 0 0 143 0 144 63
96 c142 220 202 317 202 329 0 10 -28 13 -115 13 l-115 0 -40 -70 c-22 -39
-55 -94 -73 -124 l-33 -54 -25 36 c-13 20 -24 38 -24 41 0 2 -23 43 -51 90
l-50 86 -125 0 c-83 0 -124 -4 -124 -11z"/>
<path d="M8772 2938 c3 -258 4 -275 25 -313 48 -90 131 -140 259 -157 150 -20
307 20 370 95 15 18 37 52 48 77 20 42 21 62 21 305 l0 260 -115 0 -115 0 -5
-252 -5 -253 -27 -26 c-19 -20 -39 -28 -77 -32 -64 -6 -105 11 -131 53 -19 30
-20 49 -20 274 l0 241 -116 0 -115 0 3 -272z"/>
<path d="M9662 2843 l3 -368 275 0 c249 0 278 2 310 19 83 44 112 92 112 183
-1 77 -26 124 -83 154 -21 10 -39 23 -39 27 0 4 13 13 30 19 58 22 92 119 70
199 -15 53 -71 108 -127 123 -22 6 -145 11 -296 11 l-257 0 2 -367z m430 190
c22 -20 23 -70 1 -95 -13 -15 -32 -18 -110 -18 l-93 0 0 65 0 65 92 0 c71 0
95 -4 110 -17z m22 -285 c20 -28 20 -58 2 -84 -13 -17 -27 -19 -118 -19 l-103
0 -3 63 -3 62 105 0 c97 0 106 -2 120 -22z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
